import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Invoice from "../../../invoice/component";

export const List = ({ order,setOrder,stage,oldOrder }) => {

  const [prices, setPrices] = useState([]);
  // $lakes[] = ["start_date" => $lake["duration"]["start"],"end_date" => $lake["duration"]["end"]];

  const order_transform = (order) =>
  {
    const order_copy = order.map((e, k) => {
      let price = 0;
      if(prices.length > 0 )
      {
        price = prices[k].price;
        return {lake:e.lake,price:price,duration:{obj:e.duration,start:e.date.from.toISOString().slice(0,10),end:e.date.to.toISOString().slice(0,10)},method:e.method} ;
      }
    })
    return order_copy;
  }

  async function getPrice(signal) {
    const json = order.map((e) => {
      return {
        duration: e.duration.id,
        lake: e.lake.id,
        method: e.method.id,
        season: e.season.id,
      };
    });
    const params = new URLSearchParams(window.location.search);
    const api_url = "https://wedkarzonline.pl/app-api/";
    const farmid = params.get("fishingfarm");

    const response = await fetch(api_url + farmid + "/get_price", {
      method: "POST",
      signal : signal.signal,
      body: JSON.stringify({order:json} ),
    });
    const res = await response.json();
    setPrices(res);
  }

  useEffect(() => {
    const signal = new AbortController();
    getPrice(signal);
    return () => {
      signal.abort();
    };
  }, [order]);

  useEffect(() => {
    if(prices.length > 0 && order.length > 0)
    {
      const x = order_transform(order);
      setOrder(x);
    }
    return () => {
      
    };
  }, [prices]);
  
  return (
    <Invoice items={order} prices={prices} col_span="col-span-2" stage={stage} oldOrder={oldOrder}></Invoice>

  );
};
