import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Calendar from "react-calendar";

const CalendarControl = ({ duration_settings = null, season_settings ,onDateSelect}) => {
  const [calendar_end, setCalendarEnd] = useState(null);
  const [calendar_start, setCalendarStart] = useState(null);
  const [calendarSettings, setCalendarSettings] = useState({
    min_date: new Date(),
    max_date: new Date(),
  });
  
  useEffect(() => {
    if (duration_settings === null) 
    {
      setCalendarStart(null);
      setCalendarEnd(null);
      return;
    
    }

    if (duration_settings.duration.fullSeason !== null) {
      setCalendarEnd(calendarSettings.max_date);
      setCalendarStart(calendarSettings.min_date);
    } else {
      setCalendarStart(calendarSettings.min_date);
      setCalendarEnd(dateEnd(calendarSettings.min_date));
    }
  }, [duration_settings,calendarSettings]);

  useEffect(() => {
    if (season_settings === null) return;

    const server_response = {
      begin: season_settings.date.start.date.split(" ")[0].split("-"),
      end: season_settings.date.end.date.split(" ")[0].split("-"),
    };

    var today = new Date();
    var begin = new Date();
    var end = new Date();

    begin.setFullYear(
        server_response.begin[0],
        server_response.begin[1] - 1,
      server_response.begin[2]
    );

    end.setFullYear(
        server_response.end[0],
      server_response.end[1] - 1,
      server_response.end[2]
    );

    if (end < begin) {
      end.setFullYear(end.getFullYear() + 1);
    }

    if (begin < today && today < end) {
      begin = today;
    }

    setCalendarSettings((prev) => {
      return { ...prev, min_date: begin, max_date: end };
    });




  }, [season_settings]);

  useEffect(() => {
    onDateSelect(null,{date: {from:calendar_start,to:calendar_end}});
  }, [calendar_end,calendar_start]);



  function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
   }
   
  const dateEnd = (date) => {
    const end_date = new Date();
    const [months, days] = [
      duration_settings.duration.months === null
        ? 0
        : duration_settings.duration.months,
      duration_settings.duration.days === null
        ? 0
        : duration_settings.duration.days - 1,
    ];

    end_date.setFullYear(
      date.getFullYear(),
      date.getMonth() + months,
      date.getDate() + days
    );

    if (duration_settings.duration.months !== null) {
      
      if (
        monthDiff(date,end_date) > 1 * duration_settings.duration.months
      ) {
        end_date.setMonth(end_date.getMonth(),0)
      }
    }

    return end_date;
  };

  const selectDate = (e) => {
    if (duration_settings === null) return;
    else {
      let end = dateEnd(e);
      if (end > calendarSettings.max_date) end = calendarSettings.max_date;
      setCalendarEnd(end);
       e.setTime(e.getTime() + 60 * 60 * 1000);
      setCalendarStart(e);
    }
  };

  
  
  return (
    <div >


      <Calendar
        className="!w-full"
        locale="pl-PL"
        value={[calendar_start, calendar_end]}
        onChange={selectDate}
        minDate={calendarSettings.min_date}
        maxDate={calendarSettings.max_date}
      />
    </div>
  );
};

export default CalendarControl;
