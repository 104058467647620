import React, {useEffect, useState} from "react";
import Item from "./item";
import {Discount} from "./discount";

const Invoice = ({ items,disabled = false,onNextStage,col_span="col-span-3 md:col-span-1",stage,oldOrder}) => {
    const [prices, setPrices] = useState([]);
    const [total, setTotal] = useState(0);
    const itemsFiltered= items.filter((e)=>e["duration"]!==null);
    const json=itemsFiltered.map((e)=>{
            return {
                duration: e.duration.id,
                lake: e.lake.id,
                method: e.method.id,
                season: e.season.id,
            };
    })
    useEffect(() => {
        getPrice(json)
    }, [onNextStage]);

    function getTotalPrice(res)
    {
        let price=0
        res.forEach((e)=>{
            price+=e.price
        })
        setTotal(price)
    }
    async function getPrice(json) {
        const params = new URLSearchParams(window.location.search);
        const api_url = "https://wedkarzonline.pl/app-api/";
        const farmid = params.get("fishingfarm");
        const response = await fetch(api_url + farmid + "/get_price", {
            method: "POST",
            // signal : signal.signal,
            body: JSON.stringify({order:json} ),
        });
        const res = await response.json();
        if (res.length>0){
            setPrices(res);
            getTotalPrice(res);
        }
        else{
            setPrices([]);
            setTotal(0);
        }
    }

  return (
    <div className={`overflow-y-auto  ${col_span} bg-fishes-white rounded-lg max-h-full`}>
      <p className="text-center text-lg font-semibold my-2">Twoje zamówienie</p>
            {itemsFiltered.map((e, k) => {
                let price = 0;
                if(prices.length === itemsFiltered.length )
                {
                    price = prices[k].price;
                }
                return <Item  key={k} {...{...e,price:price}}></Item>;
            })}
        <p className="text-end px-2 font-semibold my-2">Razem: {total} zł</p>
        {stage === 3?  <Discount  total={total} oldOrder={oldOrder} />: null }
      {disabled ? (
        <button onClick={onNextStage} class="btn-primary px-4 py-2 my-2 rounded-lg mx-auto block">
          Przejdź do podsumowania
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Invoice;
