import React, { useEffect } from "react";
import { useState } from "react";

import "react-calendar/dist/Calendar.css";
import Invoice from "../../invoice/component";
import Order from "../../order/component";

const LakeChoice = ({
  setFooter,
  nextStage,
  prevStage,
  setOrder,
  addOrder = () => {},
}) => {
useEffect(() => {
  
  setFooter([]);
}, []);
  // const [lakes, setLakes] = useState([]);
  // const [actualLake, setactualLake] = useState(null);
  // const [order, setOrder] = useState([]);

  // const moveToNextStage = () => {
  //   addOrder(order);
  //   nextStage();
  // }

  // useEffect(() => {
  //   if(order.length > 0)
  //   {
  //     setFooter([{disabled:false, class:"btn-green",text:"Kup",action: moveToNextStage  }]);
  //   }
  //   else
  //   {
  //     setFooter([{disabled:true, class:"btn-red",text:"Kup", action: () => {} }]);
  //   }
  //   return () => {};
  // }, [order]);

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const api_url = "https://ezezwolenie.pl/app-api/";
  //   const farmid = params.get("fishingfarm");
  //     async function getLakesInfo() {

  //         const response = await fetch(api_url + farmid + "/combo_settings",{
  //           method: "GET",
  //          });

  //         const jsonData = await response.json();
  //          setLakes(jsonData);
  //       }

  //       getLakesInfo();

  //     return () => {};
  // }, []);

  // useEffect(() => {
  //   return () => {};
  // }, [lakes]);

  // const addToOrder = (data) => {
  //   Date.prototype.yyyymmdd = function() {
  //     var mm = this.getMonth() + 1;
  //     var dd = this.getDate();

  //     return [this.getFullYear(),
  //             (mm>9 ? '' : '0') + mm,
  //             (dd>9 ? '' : '0') + dd
  //            ].join('-');
  //   };

  //   const order_item = {
  //     lake : actualLake.id,
  //     duration : data.duration.id,
  //     duration_dates : {
  //       start : data.startDate.yyyymmdd(),
  //       end : data.endDate.yyyymmdd()
  //     },
  //     season : data.season[0].id,
  //     method : data.method.id
  //   }

  //   async function getLakesInfo(jsonData) {
  //     const params = new URLSearchParams(window.location.search);
  //     const api_url = "https://ezezwolenie.pl/app-api/";
  //     const farmid = params.get("fishingfarm");
  //     const response = await fetch(api_url + farmid + "/get_price/" + jsonData.lake,{
  //       method: "POST",
  //       body: JSON.stringify(jsonData),
  //      });

  //     const jsonData_ = await response.json();
  //      if(jsonData_.status)
  //      {
  //       setOrder(x=> {
  //         x = [...x,{price:jsonData_.price, lake : actualLake,duration : {obj : data.duration , start : data.startDate.yyyymmdd(), end : data.endDate.yyyymmdd()}, season : data.season , method : data.method}];
  //         return x;
  //       });

  //       setactualLake(null);

  //     }
  //   }
  //   getLakesInfo(order_item);
  // }

  // const lakeAdd = (lakeInfo) => {

  //   setLakes((e) => {
  //     e = [...e,lakeInfo]
  //     return e;
  //   });

  //   setOrder((e) => {
  //     e = e.filter((v,i,arr) => {
  //       return v.lake.id !== lakeInfo.id;
  //     });
  //     return e;
  //   });
  // };
  /* <div className="col-span-2">
            {
            actualLake === null? 
            <Choice select_elements={lakes} callback={ (e) => {
              setactualLake(e); 
              setLakes( 
                (l) => {
                  l = l.filter((v,i,arr) => {
                    return v !== e;
                  });
                  return l
                })}} 
            />
            :
            <Settings lake={actualLake}  onCancel={(e) => {
              if(actualLake !== null)
              {
                setactualLake(null); 
                setLakes( l => [...l,actualLake]);
              }
            }}

            onGenerate={addToOrder}
            />
            }
            </div>
            <div className="col-span-1">
              <Invoice onRemove={lakeAdd} order_elements={order}/>             
            </div> */

  const [items, setItems] = useState([
    {
      lake: null,
      price: null,
      date: { from: null, to: null },
      method: null,
      season: null,
      duration:null,
    },
  ]);
const [active, setActive] = useState(false);
  const updateOrderItem = (item) => {
    setItems((prev) => {
      prev[prev.length - 1] = { ...prev[prev.length - 1], ...item };
      return [...prev];
    });
  };
  const createNewItem = () => {
    setItems((prev) => {
      return [
        ...prev,
        {
          lake: null,
          price: null,
          date: { from: null, to: null },
          method: null,
          season: null,
          duration:null,
        },
      ];
    });
    setActive(true);
  };

  const nextStage_= () => {
    setOrder(items.slice(0,-1));
    nextStage();
  }

  return (
    <div className="grid grid-cols-3 gap-3 px-3 py-1">
      <Order
        onItemChange={updateOrderItem}
        items={items}
        onOrderItemComplete={createNewItem}
      />
      <Invoice items={[...items]} disabled={active} onNextStage={nextStage_} />
    </div>
  );
};

export default LakeChoice;
