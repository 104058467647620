import { useState, useEffect, useRef } from "react";
import { StageBar } from "./components/stagebar/stagebar";
import { Stage } from "./components/stages/stages";

function App() {
  const params = new URLSearchParams(window.location.search);

  const [stage, setStage] = useState(1);

  const [order, setOrderState] = useState({
    customer: {
      name: "",
      surname: "",
      email: "",
      fishingCard: "",
      discount: "",
      birth: "",
      address: {
        city: "",
        postalCode: "",
        street: "",
        building: {
          nr: "",
          apartment: "",
        },
      },
      rules: {
        privacy: "false",
        sell: "false",
      },
    },
    order: {
      lakes: [],
      rules: "false",
      invoice: ""
    },
  });

  const [farmInfo, setFarmInfo] = useState(null);

  const setOrder = (values) => {
    setOrderState((prevState) => {
      eval(`prevState.${values.target} = "${values.value}"`);
      return { ...prevState };
    });
  };

  const nextState = () => {
    setStage(stage + 1);
  };
  const prevState = () => {
    setStage(stage - 1);
  };

  const stagebarOptions = [
    "Informacje",
    "Wybór Jeziora",
    "Kup ezezwolenie",
    "Jedź na ryby",
  ];

  const StageObj = {
    stage: stage,
    maxStage: stagebarOptions.length,
    minStage: 1,
  };

  const api_url = "https://wedkarzonline.pl/app-api/";
  const farmid = params.get("fishingfarm");

  async function getFarmInfo() {
    const response = await fetch(api_url + farmid + "/info", {
      method: "GET",
    });

    const jsonData = await response.json();
    setFarmInfo(jsonData);
  }

  useEffect(() => {
    getFarmInfo();
    return () => {};
  }, []);

  return (
    <div className="App overflow-y-auto">
      <div className="container mx-auto w-full">
        <div>
          <h1 className="font-bold text-center pt-3 text-lg">
            {farmInfo ? farmInfo.name : "E-zezwolenia24"}
          </h1>

          {farmInfo ? (
            <div className="items-center flex-col md:flex-row flex justify-around py-2">
              <span className="font-semibold text-fishes-blue-dark">
                <i className="bi bi-geo-alt"></i> {farmInfo.address}
              </span>
              <span className="font-semibold text-fishes-blue-dark">
                <i className="bi bi-telephone"></i>{" "}
                <a href={"tel:+" + farmInfo.phone}>{farmInfo.phone} </a>
              </span>
              <span className="font-semibold text-fishes-blue-dark">
                <i className="bi bi-envelope"></i>{" "}
                <a href={"mailto:" + farmInfo.email}>{farmInfo.email}</a>
              </span>
            </div>
          ) : (
            <div className="text-center py-2">Ładowanie danych jeziora</div>
          )}
        </div>

        <StageBar items={stagebarOptions} stage={stage}></StageBar>
        <Stage
          farmInfo={farmInfo}
          nextStage={nextState}
          stage={StageObj}
          prevStage={prevState}
          setOrder={setOrder}
          order={order}
          setOrderManually={setOrderState}
        />
      </div>
            
      <div className="mt-2 flex justify-between container mx-auto">
        <div></div>
        <div className="text-xs">
          Powered by : <b>wedkarzonline.pl</b>
        </div>
      </div>
    </div>
  );
}

export default App;
