import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const List = ({
  items,
  active,
  name,
  ico,
  onSelect,
  autoSelect,
  preSelected = "null",
  ...props
}) => {
  const [active_, setactive_] = useState(true);
  const [items_, setItems] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(null);
  const [autoSelect_, setAutoSelect] = useState(false);

  let e=document.querySelectorAll('option')
  e.forEach(x=>{
    if(x.textContent.length>30)
      x.textContent=x.textContent.substring(0,30)+'...';
  })

  useEffect(() => {
    if (active !== undefined) {
      setactive_(active);
    }
    setItems(items);
    if (autoSelect !== undefined) {
      setAutoSelect(autoSelect);
    }
  }, []);

  useEffect(() => {
    let selected_is_on_list = false;
    if (items !== null, selectedItem !== null) {
      items.forEach((element) => {
        if (element.id === selectedItem.id) {
          selected_is_on_list = true;
        }
      });

      if (!selected_is_on_list) {
        setSelectedItem(null);
        onSelect({id:null,name:""})
      }
    }

    setItems(items);
  }, [items]);

  const onSelect_ = (e) => {
   
    onSelect(selectedItem);
  };

  const selectHandle = (e) => {
    if (e.target.value === "null") {
      setSelectedItem(null);
      if(autoSelect_)
      {
        onSelect({id:null,name:""});
      }
    } else {
      const found = items_.find((element) => element.id == e.target.value);
      setSelectedItem(found);
      if(autoSelect_)
      {
        onSelect(found);
      }
    }

  };

  return (
    <div {...props}>
      <div className="grid grid-flow-row items-center justify-center w-full gap-2">
        <div className="row-span-1">
          <p className="text-base font-bold text-fishes-blue-dark text-center mb-0">
            {name}
          </p>
        </div>
        {ico !== false ? (
          <div className="row-span-1">
            <div className="flex w-full ">
              <i className={`d-block mx-auto text-7xl ${ico}`}></i>
            </div>
          </div>
        ) : (
          <></>
        )}
        {items.length > 0 || !active_ ? (
          <div className="row-span-1">
            <div className="flex justify-center">
              <select
                  style={{textAlignLast:"center",width:"90%"}}
                value={preSelected === "null" || preSelected === null? selectedItem === null?"null":selectedItem.id: selectedItem === null? preSelected.toString():selectedItem.id.toString()}
                onChange={selectHandle}
                className="px-2 rounded-lg text-sm font-semibold text-fishes-blue-dark"
                name="waterName"
                disabled={items_ !== undefined ? false : true}
              >
                {selectedItem === null && preSelected === "null" || preSelected === null ? (
                  <option value="null">Wybierz</option>
                ) : (
                  <></>
                )}
                {items_ !== undefined && items_.length > 0 ? (
                  items_.map((w, k) => {
                    return (
                      <option style={{maxWidth:"80%"}} key={w.id} value={w.id}>
                        {w.name}
                      </option>
                    );
                  })
                ) : (
                  <option value="null">Brak</option>
                )}
              </select>
            </div>
          </div>
        ) : (
          <div className="row-span-1 mx-auto">
            <div className="border-fishes-gray h-5 w-5 animate-spin rounded-full border-2 border-t-fishes-blue-dark" />
          </div>
        )}
        {!autoSelect_ ? (
          <button
            disabled={items.length > 0 ? selectedItem === null? true:false : true}
            onClick={onSelect_}
            className="w-fit mx-auto rounded-sm font-semibold px-3 py-1 rounded-xl text-sm text-fishes-white bg-fishes-blue-dark"
          >
            Akceptuj
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
